<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container is-max">
          <h2 class="has-text-centered is-3 title">
            {{ $t('title') }}
          </h2>
          <div class="is-size-7 text-center">
            {{ $t('desc') }}
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mini">
        <ForgotPasswordForm
          ref="forgotPasswordForm"
          :is-submitting="isSubmitting"
          @submit="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en":{
"title": "Reset Your Password",
"desc":"Enter your email address below to receive new password reset link to your email.",
"success-message": "We have sent an email to {email} with a link to reset your password."
},
"ja":{
"title": "パスワードをリセット",
"desc":"パスワードリセット用のリンクを受け取るためのメールアドレスを入力してください",
"success-message": "パスワードリセット用のリンクが記載されたメールを{email} に送信しました"
}
}
</i18n>

<script>
import { mapActions } from 'vuex';
import ForgotPasswordForm from '@/views/user/components/ForgotPasswordForm';

export default {
  name: 'ForgotPassword',
  components: { ForgotPasswordForm },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions('user', ['forgotPassword']),
    handleSubmit(data) {
      this.isSubmitting = true;
      this.forgotPassword(data)
        .then(() => {
          this.isSubmitting = false;
          this.$message.success(this.$t('success-message', { email: data.email }));
        })
        .catch((err) => {
          console.log(err);
          this.isSubmitting = false;
        });
    },
  },
};
</script>
