<template>
  <a-form
    ref="forgotPasswordForm"
    :model="forgotPasswordForm"
    :rules="forgotPasswordRules"
    class="columns is-multiline"
  >
    <a-form-item class="column is-12" :label="$t('Email')" name="email">
      <a-input
        v-model:value.trim="forgotPasswordForm.email"
        :size="formSize"
        autocomplete="email"
        type="email"
      />
    </a-form-item>
    <div class="column is-12">
      <a-button
        :loading="isSubmitting"
        :size="formSize"
        class="float-right"
        style="min-width: 155px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Submit') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
"en":{
"Email":"Email",
"invalid-email":"Invalid email format.",
"email-required":"Email is required.",
"Submit": "Submit"
},
ja:{
"Email":"メールアドレス",
"invalid-email":"無効なメールアドレス形式",
"email-required":"メールアドレスは必須です",
"Submit": "提出する"
}
}
</i18n>

<script>
export default {
  name: 'ForgotPasswordForm',
  props: {
    isSubmitting: {
      type: Boolean,
    },
  },
  emits: ['submit'],
  data() {
    return {
      forgotPasswordForm: {
        email: '',
      },
      forgotPasswordRules: {
        email: [
          {
            type: 'email',
            message: this.$t('invalid-email'),
            trigger: ['change', 'blur', 'input'],
          },
          {
            required: true,
            message: this.$t('email-required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      formSize: 'large',
    };
  },
  methods: {
    handleSubmit() {
      this.$refs.forgotPasswordForm
        .validate()
        .then(() => {
          this.$emit('submit', { ...this.forgotPasswordForm });
        })
        .catch(() => {});
    },
  },
};
</script>
